import _ from 'lodash'

export default {
  data() {
    return {}
  },
  methods: {
    mqttIncomingHandler(payload) {
      console.log(`mqttIncomingHandler: ${payload.event_type}: `, payload)
      const eventType = payload.event_type
      if (eventType === 'new_room_created') {
        this.handleEventNewRoomCreated(payload)
      } else if (eventType === 'user_online_status') {
        this.handleEventUserOnlineStatus(payload)
      } else if (eventType === 'agent_take_room') {
        this.handleEventAgentTakeRoom(payload)
      } else if (eventType === 'resolve_room') {
        this.handleEventResolveRoom(payload)
      }
    },
    handleEventAgentTakeRoom(data) {
      const payload =
        typeof data?.data === 'object' ? data.data : JSON.parse(data.data)

      if (this.$auth.user.role === 'agent') {
        // Store metric to mixpanel
        this.storeMixpanelMetric('Get Chat', {
          Role: this.$auth.user.role,
          'Company ID': this.getOrganizationForMetric()['Company ID'],
          'Company Name': this.getOrganizationForMetric()['Company Name'],
          Channel: this.getChannelName(payload?.extra?.room?.channel ?? ''),
        })
      }

      const agentName = /<b>(.*?)<\/b>/g.exec(data.body)

      payload.extra.room.last_message = {
        type: 'notification',
        text: this.isAgent()
          ? 'You have taken this conversation'
          : agentName
          ? `${agentName[0]} taken this conversation`
          : data.body,
        extra: data.extra || '',
      }

      const index = _.findIndex(this.roomLists, { id: payload.extra.room.id })
      if (index < 0) {
        this.$store.commit('inbox/UPDATE_UNSHIFT_ROOM', {
          room: payload.extra.room,
        })
      } else {
        this.$store.commit('inbox/UPDATE_LIFT_UP_ROOM', {
          index,
          room: payload.extra.room,
        })
      }

      // UDPATE TOTAL UNREAD COUNT TABS
      const unread = payload?.extra?.room?.unread_count
      const channel = payload?.extra?.room?.channel
      this.updateTotalUnreadCountTabs(unread, channel)
      const isNotHaveSLA = ['wa_group', 'google_my_business', 'ig_comment']
      if (!isNotHaveSLA.includes(payload.extra.room.channel)) {
        this.$store.dispatch('inbox/getRoomSLA', {
          ids: [payload.extra.room.id],
        })
      }
    },
    async handleEventResolveRoom(data) {
      // Parsing to JSON
      data.data =
        typeof data.data === 'object' ? data.data : JSON.parse(data.data)
      const dataFormatted = this.incomingFcmRoomFormatter(data)
      const room = {
        id: dataFormatted.room_id,
        name: data.data.extra.room.name,
      }

      // Update to conversation if on the same room
      if (this.$route.name === 'inbox') {
        // await this.$store.dispatch('inbox/getRoomSpecificInfo', { target: 'unassigned' })
        if (this.$route.query.room) {
          if (
            this.$route.query.room === dataFormatted.room_id &&
            !this.conversationInformation.resolved
          ) {
            this.$store.commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Warning!',
                item: 'Room has been resolved',
                closeable: true,
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )

            await this.$store.commit(
              'conversation/EDIT_CONVERSATION_INFORMATION',
              {
                type: 'status',
                value: {
                  status: 'resolved',
                },
              }
            )

            await this.$store.commit(
              'conversation/EDIT_CONVERSATION_INFORMATION',
              {
                type: 'resolved',
                value: {
                  resolved: true,
                },
              }
            )
          }
        }
        // Remove from room list because it is no longer "resolved"
        this.$store.commit(
          'inbox/REMOVE_ROOM_EXCEPT_ON_SAME_FILTER',
          {
            id: room.id,
            filter: 'resolve',
          },
          { root: true }
        )
        dataFormatted.unread_count = 0
        // if (this.roomFilter.conversation === 'resolved' || !this.roomFilter.conversation) this.sendingMessageHandler(dataFormatted)
        // Update SLA inbox
        const roomId = this.conversationInformation.id || this.$route.query.room
        if (roomId)
          this.$store.dispatch('conversation/getInboxSLAByActor', {
            room_id: this.conversationInformation.id || this.$route.query.room,
          })
        const isNotHaveSLA = ['wa_group', 'google_my_business', 'ig_comment']
        if (
          data.room?.status === 'assigned' &&
          !isNotHaveSLA.includes(data.room.channel)
        )
          this.$store.dispatch('inbox/getRoomSLA', { ids: [roomId] })
      }

      this.updateToNotificationBell(room, dataFormatted)
    },
    handleEventNewRoomCreated(data) {
      if (data?.meta?.info?.total) {
        const unassigned = data?.meta?.info?.total
        this.$store.commit('inbox/UPDATE_EXACT_UNASSIGNED_CHAT', unassigned)
      } else {
        this.$store.dispatch('inbox/getRoomSpecificInfo', {
          target: 'unassigned',
        })
      }
    },
    handleEventUserOnlineStatus(data) {
      const user = this.$auth.user
      if (user.id === data?.data.user_id) {
        const userStatus = {
          is_online: data?.data?.is_online,
          status: data?.data.status,
          timestamp: data.data.timestamp,
        }
        this.$store.commit('layouts/UPDATE_USER_STATUS', userStatus)
      }
    },
  },
}
